import React from 'react';

const PreviewTable = props => {
  return (
    <div className="table-responsive">
    <table className="table table-striped">
      <tbody>
        <tr>
          <th>Nombre</th>
          <td>{props.customer.firstName}</td>
        </tr>
        <tr>
          <th>Apellidos</th>
          <td>{props.customer.lastName}</td>
        </tr>
        <tr>
          <th>RUT</th>
          <td>{props.customer.rutformat}</td>
        </tr>
        <tr>
          <th>Fecha de Nacimiento</th>
          <td>{props.customer.birthdate}</td>
        </tr>
        <tr>
          <th>Email</th>
          <td>{props.customer.email}</td>
        </tr>
        <tr>
          <th>Celular</th>
          <td>{'56' + props.customer.phoneNumberOri}</td>
        </tr>
        <tr>
          <th>Actividad</th>
          <td>{props.customer.actividad}</td>
        </tr>
        <tr>
          <th>Región</th>
          <td>{props.customer.region}</td>
        </tr>
        <tr>
          <th>Comuna</th>
          <td>{props.customer.comuna}</td>
        </tr>
        <tr>
          <th>Dirección</th>
          <td>{props.customer.address}</td>
        </tr>
      </tbody>
    </table>
    </div>
  );
};

export default PreviewTable;
