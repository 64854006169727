import React, {Component} from 'react';
import Header from '../Home/Header';
import Steps from './Steps';
import Footer from '../Home/Footer';
import {Form, Container, Row, Col, Button} from 'react-bootstrap';
import MetlifeService from '../../services/MetlifeDataService';
import icontel from '../../assets/images/Pescadores/icono_tel.png';
import QueryString from 'query-string';
import {useHistory, withRouter, Link} from 'react-router-dom';
import NumberFormat from 'react-number-format';
import {replaceAll} from '../tools/Tools';
//Google Tag Manager
import TagManager from 'react-gtm-module';

export default class ConfirmPayment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      token_ws: props.token_ws,
      error: false,
    };   
  }
  componentWillMount() {
    this.props.history.replace('/summaryorder');
    console.clear();
  }
  componentDidMount() {
    this.props.history.replace('/summaryorder');
    //authenticate
    MetlifeService.getOrderSummary(this.state.token_ws)
      .then(res => {
        console.log(res);
        let fecha = res.data.orderCreateDate.slice(0, 10);
        fecha = replaceAll(fecha, ['-'], '');
        const fechaContrato =
          fecha.slice(6, 8) + '/' + fecha.slice(4, 6) + '/' + fecha.slice(0, 4);
        this.setState({
          email: res.data.customer.email,
          amount: res.data.payment.amount.toFixed(0),
          dateCon: fechaContrato,
          code: res.data.payment.authorizationCode,
          operation: res.data.payment.paymentTypeCode,
          rut: res.data.customer.rut,
          labelRut:
            res.data.customer.rut.toString() +
            '-' +
            res.data.customer.dvRut.toString(),
          medialink: res.data.mediaLink,
        });
        //dataLayer
        const data = {
          dataLayer: {
            event: 'ConfirmPayment',
            ecommerce: {
              purchase: {
                actionField: {
                  id: res.data.id,          // Transaction ID. Required for purchases and refunds.
                  affiliation: 'Vida Pescador',
                  revenue: res.data.payment.amount.toFixed(0),   // Total transaction value (incl. tax and shipping)
                  tax: 0.00,
                  shipping: 0.00,
                },
                products: [{          // List of productFieldObjects.
                  name: res.data.product.description,  // Name or ID is required.
                  id: res.data.product.planId,
                  price: res.data.payment.amount.toFixed(0),
                  brand: 'Seguro',
                  category: 'Vida',
                  variant: 'Pescador',
                  quantity: 1,
                }]
              }
            }
          },
          dataLayerName: 'PageDataLayer'
        };
        TagManager.dataLayer(data);
      })
      .catch(e => {
        console.log(e);

        this.setState({error: true});
      });
  }

  render() {
    return (
      <div className="App">
        <div id="contenedor">
          <div id="simulador">
            <div className="grid">
              <div className="col-12">
                <div className="llamado">
                  <h2>Confirmación de Transacción</h2>
                </div>
              </div>
            </div>
            <Steps step="3" />
            {this.state.error ? (
              <Container>
                <div className="btn-continuar">
                  <p className="center " style={{color: 'black'}}>
                    Momentáneamente nuestra página tuvo un error, presione el
                    boton nuevamente. Gracias por tu comprensión.
                  </p>
                  <br />
                  <div
                    className="accion ejecucion btn-centrado"
                    id="phone-number">
                    <Link to="/">
                      <Button variant="primary">Página de Inicio</Button>
                    </Link>
                  </div>
                  <br />
                </div>
              </Container>
            ) : (
              <div className="grid">
                <div className="col-12">
                  <div className="grid">
                    <div className="col-12">
                      <div className="table-responsive">
                        <table className="table table-striped">
                          <tbody>
                            <tr>
                              <th>Monto</th>
                              <td>
                                <NumberFormat
                                  value={this.state.amount}
                                  displayType={'text'}
                                  thousandSeparator={'.'}
                                  decimalSeparator={','}
                                  prefix={'$ '}
                                />
                              </td>
                            </tr>
                            <tr>
                              <th>Fecha de contrataci&oacute;n</th>
                              <td>{this.state.dateCon || ''}</td>
                            </tr>
                            <tr>
                              <th>Cod. Transacci&oacute;n</th>
                              <td>{this.state.code || ''}</td>
                            </tr>
                            <tr>
                              <th>N° Operaci&oacute;n</th>
                              <td>{this.state.operation || ''}</td>
                            </tr>
                            <tr>
                              <th>RUT</th>
                              <td>{this.state.labelRut || ''}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <br />
                      <div id="medio-pago">
                        <div className="m-bottom">
                          <h3>Descarga tu Certificado de Cobertura</h3>
                        </div>
                      </div>
                    </div>
                    <div className="grid">
                      <div className="col-12">
                        <div className="btn-acciones">
                          <a
                            className="accion ejecucion btn-centrado"
                            target="_blank"
                            href={this.state.medialink}>
                            Descargar
                          </a>
                        </div>
                        <div id="total">
                          <h4>
                            Tu Certificado de Cobertura también ha sido enviado
                            a <br />
                            <strong>{this.state.email || ''}</strong> <br />
                            <span>
                              Para revisarlo, deberás ingresar una clave,
                              correspondiente a tu Rut sin guión ni dígito
                              verificador.
                            </span>
                          </h4>
                        </div>
                        <div id="telefonos">
                          <img src={icontel} />
                          <a href="tel:6002007000">600 200 7000</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}
