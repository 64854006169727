import React, {Component} from 'react';
import Header from '../Home/Header';
import Steps from './Steps';
import Footer from '../Home/Footer';
import Banner from '../Home/Banner';
import MetlifeService from '../../services/MetlifeDataService';
import ReactDOM from 'react-dom';
import {useHistory, withRouter, Link} from 'react-router-dom';
import {Form, Container, Row, Col} from 'react-bootstrap';
import loading from '../../assets/images/Pescadores/loading-orange.gif';
import icontel from '../../assets/images/Pescadores/icono_tel.png';

export default class PaymentBtn extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isFetching: false,
      token: null,
      webpay_url: null,
      error: false,
      token_ws: null,
      order: null,
      newCustomer: null,
    };
    //validate si viene desde el inicio
    if (this.props.location.state != null) {
      this.state.order = this.props.location.state.order;
      this.state.newCustomer = this.props.location.state.newCustomer;
    } else {
      this.props.history.push('/');
    }
  }

  componentDidMount() {
    this.setState({isFetching: true});

    //console.log("orderId: " + this.state.order.id);

    MetlifeService.createPaymentWebpay(this.state.newCustomer.token, {
      amount: Number(this.state.order.payment.amount.toFixed(0)),
      orderId: this.state.order.id,
      sessionId: this.state.newCustomer.payment.sessionId,
      productCode: 'app',
    })
      .then(res => {
        this.setState({
          token_ws: res.headers['token_ws'],
          webpay_url: res.headers['location'],
        });
        this.setState({isFetching: false});

        // AUTOSUBMIT
        document.getElementById('form-webpay').submit();
      })
      .catch(err => {
        console.log(err);
        this.setState({error: true});
      });
  }

  render() {
    return (
      <div className="App">
        <div className="wrap" id="contenedor1">
          <Header />
          <Banner />

          {this.state.isFetching ? (
            <div className="btn-continuar">
              <img className="center" src={loading} />
              {this.state.error && (
                <p className="center " style={{color: '#fa9600'}}>
                  Momentáneamente nuestra página tuvo un error, presione el
                  boton nuevamente. Gracias por tu comprensión.
                </p>
              )}
            </div>
          ) : (
            <div id="contenedor">
              <div id="simulador">
                <div className="grid">
                  <div className="col-12">
                    <div className="llamado">
                      <h2>Confirmación de Datos</h2>
                    </div>
                  </div>
                </div>
              </div>
              <Steps step="2" />
              <div className="grid">
                <div className="row">
                  <div className="form-general" style={{width: '100%'}}>
                    <div className="btn-acciones">
                      <form
                        id="form-webpay"
                        name="form-webpay"
                        method="post"
                        action={this.state.webpay_url}>
                        <input
                          type="hidden"
                          name="token_ws"
                          value={this.state.token_ws || ''}
                        />
                        <div className="btn-continuar">
                          <button
                            className="ui-button ui-widget ui-state-default ui-corner-all ui-button-text-only btn btn-default"
                            type="submit">
                            <span className="ui-button-text ui-c ">
                              Redirigiendo al sitio de pagos...
                            </span>
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                  <br />
                  <div style={{textAlign: 'center'}}>
                    <Link
                      to={{
                        pathname: '/insured',
                        state: {newCustomer: this.state.newCustomer},
                      }}
                      className="botonvolver"
                      style={{fontWeight: 'bold'}}>
                      Volver
                    </Link>
                  </div>
                  <div id="telefonos">
                    <img src={icontel} />
                    <a href="tel:6002007000">600 200 7000</a>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        <Footer />
      </div>
    );
  }
}
