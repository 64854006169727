import React, {Component} from 'react';
import Header from '../../Home/Header';
import Banner from '../../Home/Banner';
import Steps from '../Steps';
import Footer from '../../Home/Footer';
import InsuredForm from './InsuredForm';
import DatosSeg from './DatosSeg';

export default class Insured extends Component {
  constructor(props) {
    super(props);

    //console.log('xx', this.props.location);
    this.state = {
      isFetching: false,
      token: null,
      token_ws: null,

      newCustomer: {},
    };

    if (this.props.location.state != null) {
      this.state.newCustomer = this.props.location.state.newCustomer;
    } else {
      this.props.history.push('/');
    }
  }

  //

  render() {
    return (
      <div className="App">
        <Header />
        <Banner />
        <div id="contenedor">
          <div id="simulador">
            <DatosSeg />
            <Steps step="1" />
            <InsuredForm newCustomer={this.state.newCustomer} />
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}
