import React, {Component} from 'react';
//import logo from './logo.svg';
import {BrowserRouter, Route, Switch} from 'react-router-dom';

import Home from '../components/Home/Home';
import Insured from '../components/Order/Insured/Insured';
import PreviewOrder from '../components/Order/PreviewOrder/PreviewOrder';
import PaymentBtn from '../components/Order/PaymentBtn';
import ConfirmPayment from '../components/Order/ConfirmPayment';
import Webpayerror from '../components/Order/Webpayerror';

import Condiciones from '../components/Condiciones';
import NotFound from '../components/NotFound';

import QueryString from 'query-string';

// css
// import '../assets/css/Home/Header.css';

function App() {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/insured" component={Insured} />
        <Route exact path="/previeworder" component={PreviewOrder} />
        <Route exact path="/paymentbtn" component={PaymentBtn} />
        <Route
          exact
          path="/summaryorder"
          render={props => {
            const params = QueryString.parse(props.location.search);
            const token_ws = params.token_ws;

            return <ConfirmPayment token_ws={token_ws} {...props} />;
          }}
        />
        <Route exact path="/webpayerror" component={Webpayerror} />

        <Route exact path="/condiciones" component={Condiciones} />
        <Route component={NotFound} />
      </Switch>
    </BrowserRouter>
  );
}

export default App;
