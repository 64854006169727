import React, { Component } from 'react';

const hide = {
    display: 'none'
}

export default class NoElegible extends Component {
    constructor(props) {
        super(props);
        this.close = this.close.bind(this);
    }

    componentDidUpdate() {
        if(this.props.show) {
            const self = this;

            const topPx = (window.pageYOffset || document.scrollTop)  - (document.clientTop || 0);

            let back = document.getElementById("modal-Pescador");

            if(back == null) {
                back = document.createElement("div");
                back.id = "modal-Pescador";
                back.style = "background-color: black; opacity: 0.5; top: " + topPx + "px; width: 100%; height: 100%; position: absolute; z-index: 9999;";
                document.body.appendChild(back);
            } else {
                back.style = "background-color: black; opacity: 0.5; top: " + topPx + "px; width: 100%; height: 100%; position: absolute; z-index: 9999;";
            }
            
            document.body.style = 'overflow: hidden;';
        }
    }

    close() {
        document.body.style = '';
        document.body.removeChild(document.getElementById("modal-Pescador"));
        this.props.onClose();
    }

    render() {
        return (
            <div className="modulos-grales wbullet top-yellow myModal" style={(!this.props.show? hide : {})}>
                <br/><br/><br/>
                <div>
                    <p>Estimado cliente:</p>

                    <p>Lamentamos informarte que no podrás suscribirte al plan que seleccionaste del Seguro AP Pescador, ya que no cumples las condiciones de este plan.</p>

                    <p>Te recomendamos contratar el plan 1 o 2 de este seguro.</p>
                </div>
                <br/><br/>
                <div style={{textAlign: "center"}}>
                    <button
                        className="accion contratacion"
                        variant="primary"
                        onClick={this.close}>
                        Aceptar
                    </button>
                </div>
            </div>
        )
    }
}