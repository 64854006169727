import React, {Component} from 'react';
import Header from './Header';
import Banner from './Banner';
import Plan from '../Plan/Plan';
import Footer from './Footer';

const Home = () => {
  return (
    <div className="App">
      <Header />
      <Banner />
      <Plan />
      <Footer />
    </div>
  );
};

export default Home;
