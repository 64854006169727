import React from 'react';

const resumenseguro_p = props => {
  const style = {
    fontSize: '1.5em',
    lineHeight: '40px',
    textAlign: 'center',
  };

  return (
    <div className="grid">
      <div className="col-12">
        <div className="llamado">
          <h2 ></h2>
          <p style={style}>Protección completa y expedita para ti y tranquilidad a tu familia.</p>
          <p style={style}>Protégete frente a cualquier accidente que pudieras sufrir, tanto fuera como dentro del mar.</p>
          <p style={style}>Revisa las 4 opciones que tenemos para ti:</p>
        </div>
      </div>
    </div>
  );
};

export default resumenseguro_p;
