import React, {Component} from 'react';
import Header from '../../Home/Header';
import Banner from '../../Home/Banner';
import Steps from '../Steps';
import Footer from '../../Home/Footer';
import PreviewTable from './PreviewTable';
import {Form, Container, Row, Col} from 'react-bootstrap';
import MetlifeService from '../../../services/MetlifeDataService';
import {useHistory, withRouter, Link} from 'react-router-dom';
import webpay from '../../../assets/images/Pescadores/webpay.jpg';
import bancoestado from '../../../assets/images/Pescadores/bancoestado.png';
import icontel from '../../../assets/images/Pescadores/icono_tel.png';
import NumberFormat from 'react-number-format';

class PreviewOrder extends Component {
  constructor(props) {
    super(props);

    //console.log('xxxxxxxx', this.props.location);
    this.state = {
      isFetching: false,
      token: null,
      token_ws: null,
      fields: {},
      errors: {},
      condPago: false,
      newCustomer: null,
      errorwscode: false,
      order: {
        amount: 100,
        orderId: 1,
        sessionId: 'string',
      },
      disabledbutton: false,
    };
    this.handleCheck = this.handleCheck.bind(this);
    this.handleWebService = this.handleWebService.bind(this);
    this.handleChange = this.handleChange.bind(this);
    //validate si viene desde el inicio
    if (this.props.location.state != null) {
      this.state.newCustomer = this.props.location.state.newCustomer;
      let region = this.props.location.state.newCustomer.customer.region;
      let comuna = this.props.location.state.newCustomer.customer.comuna;
      //console.log(region);
      //console.log(comuna);
      //console.log('x');
      let regionName = this.props.location.state.newCustomer.customer.region;
      switch (region) {
        case '1':
          regionName = 'Región de Tarapacá';
          break;
        case '2':
          regionName = 'Región de Antofagasta';
          break;
        case '3':
          regionName = 'Región de Atacama';
          break;
        case '4':
          regionName = 'Región de Coquimbo';
          break;
        case '5':
          regionName = 'Región de Valparaíso';
          break;
        case '6':
          regionName = "Región del Libertador General Bernardo O'Higgins";
          break;
        case '7':
          regionName = 'Región del Maule';
          break;
        case '8':
          regionName = 'Región del Bío-Bío';
          break;
        case '9':
          regionName = 'Región de la Araucanía';
          break;
        case '10':
          regionName = 'Región de Los Lagos';
          break;
        case '11':
          regionName = 'Región de Aysén del General Carlos Ibáñez del Campo';
          break;
        case '12':
          regionName = 'Región de Magallanes y la Antártica Chilena';
          break;
        case '13':
          regionName = 'Región Metropolitana';
          break;
        case '14':
          regionName = 'Región de Los Ríos';
          break;
        case '15':
          regionName = 'Región de Arica y Parinacota';
          break;
      }
      //console.log(regionName);
      this.props.location.state.newCustomer.customer.region = regionName;
      this.props.location.state.newCustomer.customer.commune =
        comuna + ', ' + regionName;
      this.setState({newCustomer: this.props.location.state.newCustomer});
    } else {
      this.props.history.push('/');
    }
    // validation
    //console.log(this.state.newCustomer);
  }

  handleCheck(e) {
    let condPago = this.state.condPago;
    condPago = e.target.checked;
    this.setState({condPago});
  }

  handleValidation() {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;

    if (!fields['code']) errors['code'] = 'Ingresa tu código';
    else if (this.state.errorwscode)
      errors['code'] = 'Ingresa un código válido';
    if (!this.state.condPago)
      errors['condPago'] = 'Acepte las condiciones de Pago';

    //console.log('Chao');

    this.setState({errors: errors});
    if (Object.keys(errors).length > 0) formIsValid = false;
    return formIsValid;
  }

  handleWebService() {
    if (this.state.disabledbutton) {
      return;
    }
    this.setState({disabledbutton: true});
    this.state.errorwscode = false;
    if (this.handleValidation()) {
      //console.log('Hola');
      this.state.newCustomer.customer['smsCode'] = this.state.fields['code'];
      //this.state.newCustomer.product['code'] = 'app';
      //this.state.newCustomer.customer['smsTime'] = '16:53:00';
      //this.state.newCustomer.customer['smsCount'] = 1;
      //  crear code in product. productCode
      this.state.newCustomer.product[
        'code'
      ] = this.state.newCustomer.product.productCode;
      // manejar el rut
      let rut = this.state.newCustomer.customer['rutformat'];
      //console.log(rut);
      let part1 = rut.slice(0, -10);
      //console.log(part1);
      let part2 = rut.slice(-9, -6);
      //console.log(part2);
      let part3 = rut.slice(-5, -2);
      //console.log(part3);
      //console.log(part1 + part2 + part3);
      this.state.newCustomer.customer['rut'] = part1 + part2 + part3;
      this.state.newCustomer.customer['dvRut'] = rut.slice(-1);

      let region = this.state.newCustomer.customer['region'];
      //console.log(region);
      let commune = this.state.newCustomer.customer['commune'];
      //console.log(commune);
      //this.state.newCustomer.customer['commune'] = region + ', ' + commune;

      this.state.newCustomer.customer.phoneNumber =
        '56' + this.state.newCustomer.customer.phoneNumberOri;

      MetlifeService.createOrderInit(
        this.state.newCustomer.token,
        this.state.newCustomer
      )
        .then(res => {
          // order
          this.state.order = res.data;
          this.props.history.push({
            pathname: '/paymentbtn',
            state: {
              order: this.state.order,
              newCustomer: this.state.newCustomer,
            },
          });
        })
        .catch(err => {
          console.log(err);
          this.setState({errorwscode: true});
          this.handleValidation();
          this.setState({disabledbutton: false});
        });
    } else {
      this.setState({disabledbutton: false});
    }
  }

  handleChange(field, e) {
    let fields = this.state.fields;
    if (field == 'payment') {
      fields[field] = e.target.id;
    } else {
      let campo = e.target.value;
      campo = campo.replace(/[^0-9]/g, '');
      fields[field] = campo;
    }
    this.setState({fields});
  }

  render() {
    const signoArriba = {
      verticalAlign: 'super',
      fontSize: '15px',
    };
    return (
      <div className="App">
        <div className="wrap" id="contenedor1">
          <Header />
          <Banner />
          <div id="contenedor">
            <div id="simulador">
              <div className="grid">
                <div className="col-12">
                  <div className="llamado">
                    <h2>Confirmaci&oacute;n de Datos</h2>
                  </div>
                </div>
              </div>
              <Steps step="2" />
              <div className="grid">
                <div className="col-12">
                  <div className="grid">
                    <div className="col-12">
                      <div className="m-bottom">
                        <h3>Confirma los datos:</h3>
                      </div>
                      <PreviewTable
                        customer={this.state.newCustomer.customer}
                      />
                      <br />
                      <div id="medio-pago">
                        <div id="total">
                          <h4>
                            Valor a pagar
                            <NumberFormat
                              value={this.state.newCustomer.product.price}
                              displayType={'text'}
                              prefix={' UF '}
                              thousandSeparator={'.'}
                              decimalSeparator={','}
                            />{' '}
                            :
                          </h4>
                          <h2>
                            <NumberFormat
                              value={this.state.newCustomer.payment.amount}
                              displayType={'text'}
                              thousandSeparator={true}
                              prefix={' $'}
                              thousandSeparator={'.'}
                              decimalSeparator={','}
                              decimalScale={0}
                            />
                          </h2>
                          <p>
                            Valor calculado según valor de la UF{' '}
                            {this.state.newCustomer.fecha_calc} por{' '}
                            <NumberFormat
                              value={this.state.newCustomer.peso_calc}
                              displayType={'text'}
                              thousandSeparator={true}
                              prefix={' $'}
                              thousandSeparator={'.'}
                              decimalSeparator={','}
                              decimalScale={0}
                            />
                          </p>
                        </div>
                        <div className="m-bottom">
                          <h3>Medio de pago:</h3>
                        </div>
                        <div id="select-medio-pago">
                          <table>
                            <tbody>
                              <tr>
                                <td>
                                  <label>
                                    <img
                                      alt="Webpay"
                                      src={webpay}
                                      className="img_choice"
                                    />
                                  </label>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div id="condiciones">
                        <input
                          id="aceptaCondiciones"
                          name="aceptaCondiciones"
                          type="checkbox"
                          className="form-control required"
                          checked={this.state.condPago}
                          onChange={this.handleCheck}
                        />
                        <Link to="/condiciones" target="_blank">
                          <label id="checkValidar" className="cont rol-label">
                            Acepto condiciones de pagos.{' '}
                            <span style={{fontWeight: 'bold', cursor: 'pointer'}}>
                              Ver condiciones
                            </span>
                          </label>
                        </Link>
                        <br />
                        {this.state.errors['condPago'] ? (
                          <span style={{color: '#a94442'}}>
                            {this.state.errors['condPago']}
                          </span>
                        ) : (
                          ''
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="grid">
                  <div className="col-12">
                    <div id="codigo">
                      <div id="condiciones3">
                        <label className="cont rol-label">
                          Ingresa el código de verificaci&oacute;n que fue
                          enviado a tu celular
                        </label>
                      </div>
                      <div className="form-general direccion">
                        <div className="form-group has-feedback">
                          <input
                            onChange={this.handleChange.bind(this, 'code')}
                            value={this.state.fields['code'] || ''}
                            type="text"
                            id="code"
                            className="form-control input_token_2fa_required"
                            name="code"
                            maxLength="6"
                            placeholder="Código de verificación"
                          />
                        </div>
                        {this.state.errors['code'] ? (
                          <span style={{color: '#a94442'}}>
                            {this.state.errors['code']}
                          </span>
                        ) : (
                          ''
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="error"
                style={{color: '#ff0000', textAlign: 'center'}}>
                <p></p>
              </div>
              <div className="row">
                <div className="form-general" style={{width: '100%'}}>
                  <div className="btn-acciones">
                    <button
                      id="contratar"
                      name="contratar"
                      className="accion ejecucion btn-centrado"
                      onClick={this.handleWebService}
                      disabled={this.state.disabledbutton}>
                      {this.state.disabledbutton
                        ? 'Enviando...'
                        : 'Pagar Ahora'}
                    </button>
                  </div>
                </div>
                <br />
                <div style={{textAlign: 'center'}}>
                  <Link
                    to={{
                      pathname: '/insured',
                      state: {newCustomer: this.state.newCustomer},
                    }}
                    className="botonvolver"
                    style={{fontWeight: 'bold'}}>
                    Volver
                  </Link>
                </div>
                <div id="telefonos">
                  <img src={icontel} />
                  <a href="tel:6002007000">600 200 7000</a>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    );
  }
}

export default withRouter(PreviewOrder);
