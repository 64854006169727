import React from 'react';
//import {Form, Container, Row, Col} from 'react-bootstrap';

//import '../assets/css/Other/bootstrap.css';
//import '../assets/css/Other/style.css';

const Condiciones = props => {
  return (
    <div className="container">
      <h4>
        <b>Condiciones de Pago</b>
      </h4>
      <div>
        <h4>
          <b>Condiciones de pago de la prima del seguro</b>
        </h4>
        <p>
          MetLife Chile Seguros de Vida S.A., pone a tu disposición el pago del
          Seguro por muerte accidental a través de WEBPAY o de Cuenta Corriente
          o Cuenta Vista de BancoEstado, conforme a las siguientes condiciones
          generales:
        </p>
        <p>
          - Para todos los efectos, sólo se entenderá pagada la prima del seguro
          una vez que el sistema apruebe la respectiva transacción y la Compañía
          Aseguradora haya recibido conforme el monto correspondiente al total
          de la prima, no permitiéndose pagos parciales.{' '}
        </p>
        <p>
          - El Certificado de Cobertura será entregado a través de e-mail, al
          momento de contratar el seguro.{' '}
        </p>
        <p>
          - Las consultas sobre el servicio, verificación de pagos y/o
          disconformidades sobre el mismo, puedes hacerlas llamando al número de
          Fono Banco: 600 200 7000 o a través de nuestra Red de Sucursales.{' '}
        </p>
        <p>
          - Para consultas de seguros, coberturas y procedimientos llamar a
          Fonoseguros: 600 660 12 12.{' '}
        </p>
        <p>
          - Si se produjera una duplicidad en el pago de la prima, se podrá
          devolver uno de estos cobros mediante solicitud del cliente a través
          del FonoSeguros o de la Red de Sucursales, siempre que los dineros se
          encuentren disponibles en la Compañía.{' '}
        </p>
        <p>
          - La Compañía Aseguradora, pone a tu disposición los señalados medios
          de pago sólo como alternativas para tu comodidad. En consecuencia, no
          se hace responsable por las condiciones de uso y de seguridad del
          medio de pago que se utilice. Estas condiciones son de responsabilidad
          del proveedor del servicio WEBPAY o de la Cuenta Corriente o Cuenta
          Vista de BancoEstado, y se rigen por los respectivos contratos de uso
          que has debido firmar con dichas instituciones, sin que corresponda
          exigir responsabilidad alguna para MetLife Chile Seguros de Vida S.A.
          por cualquier circunstancia derivada del uso de los mismos.
        </p>
        <strong>Devolución de Prima:</strong> En caso de ejercer el Derecho de
        Retracto, éste en conjunto con la devolución de la prima
        correspondiente, debe ser solicitado por el cliente dentro de los
        primeros 10 días hábiles desde la respectiva compra a través de la Red
        de Sucursales, completando el formulario respectivo. Plazo de la
        devolución: La devolución se cursará en un plazo máximo de 10 días
        hábiles, contados desde la fecha de ingreso de la solicitud.
      </div>
    </div>
  );
};

export default Condiciones;
