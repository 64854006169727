import React, { Component } from 'react';
import { Form } from 'react-bootstrap';

const hide = {
    display: 'none'
}

const styleLabel = {
    float: 'none',
    paddingLeft: '5px',
    verticalAlign: 'middle'
};

const styleRadio = {
    width: '20px',
    height: '20px'
}

const styleExit = {
    backgroundColor: 'black',
    width: '50px',
    height: '50px',
    color: 'white',
    borderRadius: '25px',
    position: 'absolute',
    right: '-20px',
    top: '-20px'
}

export default class Declaracion extends Component {
    constructor(props) {
        super(props);
        this.state = {
            padece: null,
            fields: {},
            errors: {},
        };
        this.close = this.close.bind(this);
        this.cancel = this.cancel.bind(this);

        this.handlePacede = this.handlePacede.bind(this);
        this.handleNoPacede = this.handleNoPacede.bind(this);

    }

    close() {
        if(this.state.padece == null) {
            this.setState({ errors: { 'padecer': "Por favor seleccione una opción" } });
        }
        else {
            this.setState({ errors: { } });
            this.props.onClose();

            if(this.state.padece) {
                document.body.style = '';
                document.body.removeChild(document.getElementById("modal-Pescador"));
                this.props.onFailure();
            }
            else {
                this.props.onSuccess();
                setTimeout(() => {
                    document.body.style = '';
                    document.body.removeChild(document.getElementById("modal-Pescador"));
                }, 600);
            }

            this.setState({ padece: null });
        }
    }

    handlePacede() {
        this.setState({ padece: true }); 
    }

    handleNoPacede() {
        this.setState({ padece: false }); 
    }

    cancel() {
        this.setState({ padece: null });

        document.body.style = '';
        document.body.removeChild(document.getElementById("modal-Pescador"));
        this.props.onExit();
    }

    componentDidUpdate() {
        if (this.props.show) {
            const self = this;

            const topPx = (window.pageYOffset || document.scrollTop) - (document.clientTop || 0);

            let back = document.getElementById("modal-Pescador");

            if (back == null) {
                back = document.createElement("div");
                back.id = "modal-Pescador";
                back.onclick = function () {
                    self.cancel();
                };
                back.style = "background-color: black; opacity: 0.5; top: " + topPx + "px; width: 100%; height: 100%; position: absolute; z-index: 9999;";
                document.body.appendChild(back);
            } else {
                back.style = "background-color: black; opacity: 0.5; top: " + topPx + "px; width: 100%; height: 100%; position: absolute; z-index: 9999;";
            }

            document.body.style = 'overflow: hidden;';
        }
    }

    render() {
        return (
            <div className="modulos-grales wbullet top-yellow myModal" style={(!this.props.show ? hide : {})}>
                <button style={styleExit} onClick={this.cancel}>X</button>
                <h4>Declaración Personal de Salud</h4>
                <br />
                <p>¿Tienes conocimiento de haber padecido alguna de las siguientes enfermedades o situaciones que se indican a continuación? :</p>
                <br /><br />
                <ul className='myUl declaracion'>
                    <li>Cirrosis Hepática y/o Biliar</li>
                    <li>Diabetes Melitus</li>
                    <li>Insuficiencia Renal Crónica</li>
                    <li>Lupus Eritematoso Sistémico</li>
                    <li>Obesidad Mórbida (IMC mayor a 40)</li>
                    <li>Transplante de Órganos</li>
                    <li>Cáncer (de cualquier tipo o cualquier enfermedad neoplástica)</li>
                </ul>
                <br /><br />
                <input
                    id="checkConocimientoDeclaracion"
                    name="padeceEnfermedad"
                    type="radio"
                    checked={this.state.padece}
                    onChange={this.handlePacede}
                    style={styleRadio}
                    className="form-control checkDeclaracion"
                />
                <label style={styleLabel}>
                    <span>Si he padecido alguna de las enfermedades</span>
                </label>
                <br /><br />
                <input
                    id="checkNoPadeceEnfermedad"
                    name="padeceEnfermedad"
                    type="radio"
                    className="form-control checkDeclaracion"
                    style={styleRadio}
                    checked={this.state.padece != null && !this.state.padece}
                    onChange={this.handleNoPacede}
                />
                <label style={styleLabel}>
                    <span>No he padecido ninguna de las enfermedades.</span>
                </label>
                <br /><br />
                {this.state.errors['padecer'] ? (
                    <span style={{ color: '#a94442' }}>
                        {this.state.errors['padecer']}
                    </span>
                ) : ('')}
                <br />
                <div style={{ textAlign: "center" }}>
                    <button
                        type='submit'
                        className="accion contratacion"
                        variant="primary"
                        onClick={this.close}>
                        Continuar
                    </button>
                </div>
            </div>
        )
    }
}