import React from 'react';
import headerlogo from '../../assets/images/Pescadores/logo_bech.png';
//import '../../assets/css/Home/Header.css';
import {Form, Container, Row, Col} from 'react-bootstrap';

const header = props => {
  return (
    <Container>
      <div className="inner-content-logo">
        <a href="http://www.bancoestado.cl" className="back-btn" id="menus"></a>
        <div id="logo">
          <div>
            <a href="http://www.bancoestado.cl">
              <img src={headerlogo} alt="Banco Estado" />
            </a>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default header;
