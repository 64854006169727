import React from 'react';
import banner from '../../assets/images/Pescadores/banner-2.jpg';
// import '../../assets/css/Home/Banner.css';

const banner_p = props => {
  
  return (
    <div className="header">
      <img src={banner} alt="Pescadores faenando mar adentro" title="Pescadores faenando mar adentro" />
    </div>
  );
};

export default banner_p;
