import React, {Component} from 'react';
import Header from '../Home/Header';
import Banner from '../Home/Banner';
import Steps from './Steps';
import Footer from '../Home/Footer';

import MetlifeService from '../../services/MetlifeDataService';
import ReactDOM from 'react-dom';
import {Button, Container, Row, Col} from 'react-bootstrap';
import loading from '../../assets/images/Pescadores/loading-orange.gif';
import icontel from '../../assets/images/Pescadores/icono_tel.png';

import {Link} from 'react-router-dom';
import QueryString from 'query-string';

export default class Webpayerror extends Component {
  constructor(props) {
    super(props);

    const params = QueryString.parse(props.location.search);
    this.state = {
      token_ws: null,
      error: false,
      isFetching: false,
      token: null,
      //auth
      order: null,
      newCustomer: null,
    };

    this.state.token_ws = params.token_ws;
  }

  render() {
    return (
      <div className="App">
        <div className="wrap" id="contenedor1">
          <Header />
          <Banner />        
          <div className="btn-continuar">
            
              <div className="btn-continuar">
                <p className="center " style={{color: 'black'}}>
                <p>Orden de Compra rechazada. Las posibles causas de este rechazo son:</p>
                <br />
                <ul>
                  <li>* Error en el ingreso de los datos de su tarjeta de Crédito o Débito (fecha y/o código de seguridad).</li>
                  <li>* Su tarjeta de Crédito o Débito no cuenta con saldo suficiente.</li>
                  <li>* Tarjeta aún no habilitada en el sistema financiero.</li>
                </ul>
                </p>
                <br />
                <div style={{textAlign: "center"}}>
                  <Link to="/"
                  className="botonvolver"
                  style={{fontWeight: "bold"}}>
                      Volver
                  </Link>
                </div>
              </div>
            
          </div>
        
        </div>
        <Footer />
      </div>
    );
  }
}
