import React, {Component} from 'react';
import Header from './Home/Header';
import Banner from './Home/Banner';
import Footer from './Home/Footer';
import {Container, Button} from 'react-bootstrap';

import {Link} from 'react-router-dom';

export default class NotFound extends Component {
  render() {
    return (
      <div className="App">
        <Header />
        <Banner />
        <div className="wrap" id="contenedor1">
		      <div id="contenedor2" className="container p0 alto-min">
		        <div className="row" style={{margin: "50px", minHeight: "150px"}}>
			        <div className="col-md-3">
				        <p>
                  <Link to="/">
                    <Button variant="primary">Volver al HOME</Button>
                  </Link>
				        </p>
			        </div>
			        <div className="col-md-9 border msg-error">
				        <div className="row">
					        <h3>Error 404</h3>
				        </div>
				        <div className="row">
					        <p>El recurso al que está tratando de acceder no existe.</p>
				        </div>	
			        </div>
		        </div>
	        </div>
	      </div>
        <Footer />
      </div>
    );
  }
}
