import http from './http_common';
import axios from 'axios';

class MetlifeDataService {
  getToken(data) {
    return http.post('/recaptcha/pescador', data, {
      headers: {
        'Content-type': 'application/json',
      },
    });
  }

  getUF() {
    return http.get('/currency/uf');
  }

  getCoverages() {
    return http.get('/coverages');
  }

  getPlan(name) {
    return http.get(`/plans/${name}`);
  }

  getUFPlanesCoverage(name) {
    return axios.all([this.getUF(), this.getPlan(name), this.getCoverages()]);
  }

  createOrder(token, data) {
    return http.post('/orders', data, {
      headers: {
        Authorization: token,
      },
    });
  }

  createOrderInit(token, data) {
    return http.post('/orders/initpayment', data, {
      headers: {
        Authorization: token,
      },
    });
  }

  createPaymentWebpay(token, data) {
    return http.post('/payments/webpay', data, {
      headers: {
        Authorization: token,
      },
    });
  }

  getOrderSummary(token) {
    return http.post('/orders/summary', null, {
      headers: {
        token_ws: token,
      },
    });
  }
}

export default new MetlifeDataService();
