

export const formatDate = (date, format, asd) => {

};


export const replaceAll = (texto, listViejo, nuevo) => {
  listViejo.map(viejo => {
    texto = texto.split(viejo).join(nuevo);
  });
  return texto;
};

// Funciones para validar el rut
export const validaRutDv = T => {
  var M = 0,
    S = 1;
  for (; T; T = Math.floor(T / 10)) S = (S + (T % 10) * (9 - (M++ % 6))) % 11;
  return S ? S - 1 : 'k';
};

export const validaRut = rutCompleto => {
  rutCompleto = rutCompleto.replace('‐', '-');
  if (!/^[0-9]+[-|‐]{1}[0-9kK]{1}$/.test(rutCompleto)) return false;
  var tmp = rutCompleto.split('-');
  var digv = tmp[1];
  var rut = tmp[0];
  if (digv == 'K') digv = 'k';

  return validaRutDv(rut) == digv;
};

export const getErrorMessage = code => {
  const errors = {
    '000':
      ' Momentáneamente nuestra página tuvo un error. Gracias por tu comprensión.',
    '111':
      ' Momentáneamente nuestra página tuvo un error, presione el boton nuevamente. Gracias por tu comprensión.',
    '001': 'Verifique su RUT',
    '002': 'Verifique el monto',
    '003': 'El RUT utilizado ha llegado al número máximo de compras de seguro',
    '004': 'Código de verificación es inválido',
  };
  return errors[code || '000'];
};

export const getCommunes = () => {
  return [
    'Puente Alto',
    'Maipú',
    'Santiago',
    'La Florida',
    'Antofagasta',
    'Viña del Mar',
    'San Bernardo',
    'Valparaíso',
    'Las Condes',
    'Temuco',
    'Puerto Montt',
    'Rancagua',
    'Peñalolén',
    'Pudahuel',
    'Coquimbo',
    'Concepción',
    'Arica',
    'La Serena',
    'Talca',
    'Quilicura',
    'Ñuñoa',
    'Los Ángeles',
    'Iquique',
    'Chillán',
    'La Pintana',
    'Valdivia',
    'Calama',
    'El Bosque',
    'Osorno',
    'Recoleta',
    'Copiapó',
    'Talcahuano',
    'Quilpué',
    'Curicó',
    'Renca',
    'Estación Central',
    'Colina',
    'Providencia',
    'Cerro Navia',
    'San Pedro de la Paz',
    'Punta Arenas',
    'Conchalí',
    'Villa Alemana',
    'Melipilla',
    'La Granja',
    'Macul',
    'Coronel',
    'Ovalle',
    'Quinta Normal',
    'Alto Hospicio',
    'San Miguel',
    'Lo Barnechea',
    'Lampa',
    'Pedro Aguirre Cerda',
    'Independencia',
    'Lo Espejo',
    'Huechuraba',
    'Buin',
    'Lo Prado',
    'San Joaquín',
    'Linares',
    'La Reina',
    'Hualpén',
    'San Antonio',
    'Quillota',
    'Peñaflor',
    'La Cisterna',
    'Chiguayante',
    'Vitacura',
    'San Ramón',
    'Cerrillos',
    'San Felipe',
    'Padre Las Casas',
    'Talagante',
    'San Fernando',
    'Paine',
    'Los Andes',
    'Padre Hurtado',
    'Rengo',
    'Coyhaique',
    'Villarrica',
    'Tomé',
    'Angol',
    'San Carlos',
    'Machalí',
    'Vallenar',
    'La Calera',
    'Maule',
    'Penco',
    'San Vicente',
    'Limache',
    'Constitución',
    'Molina',
    'San Javier',
    'Puerto Varas',
    'Castro',
    'Lota',
    'San Clemente',
    'Concón',
    'Parral',
    'Cauquenes',
    'Ancud',
    'La Unión',
    'Lautaro',
    'Santa Cruz',
    'Arauco',
    'Isla de Maipo',
    'El Monte',
    'Chimbarongo',
    'La Ligua',
    'Panguipulli',
    'Cañete',
    'Victoria',
    'Calbuco',
    'Graneros',
    'Curacaví',
    'Nueva Imperial',
    'Curanilahue',
    'Quintero',
    'Río Bueno',
    'Chillán Viejo',
    'Illapel',
    'Monte Patria',
    'Longaví',
    'Mulchén',
    'Salamanca',
    'Teno',
    'Cabrero',
    'Pucón',
    'Vilcún',
    'Requinoa',
    'Vicuña',
    'Quellón',
    'Coihueco',
    'Casablanca',
    'Pirque',
    'Nacimiento',
    'Lebu',
    'Calera de Tango',
    'Mostazal',
    'Tocopilla',
    'Pitrufquén',
    'Las Cabras',
    'Llaillay',
    'Freire',
    'Collipulli',
    'Carahue',
    'Hualqui',
    'Aysén',
    'Loncoche',
    'Cartagena',
    'Laja',
    'Nogales',
    'La Cruz',
    'Bulnes',
    'Natales',
    'Los Vilos',
    'Mariquina',
    'Yumbel',
    'Los Álamos',
    'Doñihue',
    'Colbún',
    'Purranque',
    'Paillaco',
    'Retiro',
    'Pichidegua',
    'Los Lagos',
    'Coltauco',
    'Cabildo',
    'Tiltil',
    'San Esteban',
    'Traiguén',
    'Puchuncaví',
    'Sagrada Familia',
    'Frutillar',
    'San José de Maipo',
    'Yerbas Buenas',
    'Hijuelas',
    'Nancagua',
    'Yungay',
    'Caldera',
    'Llanquihue',
    'Cunco',
    'Olmué',
    'Quillón',
    'Curacautín',
    'Los Muermos',
    'Putaendo',
    'Lanco',
    'Pichilemu',
    'Villa Alegre',
    'San Ignacio',
    'Coelemu',
    'El Quisco',
    'Pozo Almonte',
    'Santa María',
    'Romeral',
    'Teodoro Schmidt',
    'Chépica',
    'Chonchi',
    'Calle Larga',
    'Futrono',
    'Gorbea',
    'Peumo',
    'Maullín',
    'Tucapel',
    'Río Negro',
    'Tierra Amarilla',
    'Catemu',
    'Diego de Almagro',
    'Río Claro',
    'Algarrobo',
    'Santa Bárbara',
    'Dalcahue',
    'Santa Juana',
    'Olivar',
    'María Pinto',
    'Mejillones',
    'Malloa',
    'Combarbalá',
    'Taltal',
    'El Tabo',
    'Quinta de Tilcoco',
    'Codegua',
    'Palmilla',
    'Saavedra',
    'Fresia',
    'Chañaral',
    'El Carmen',
    'Galvarino',
    'Purén',
    'Puyehue',
    'Cholchol',
    'San Nicolás',
    'Quirihue',
    'Ñiquén',
    'Andacollo',
    'Peralillo',
    'San Pedro de Atacama',
    'Punitaqui',
    'Santo Domingo',
    'Pinto',
    'Florida',
    'Rauco',
    'Tirúa',
    'Lonquimay',
    'Renaico',
    'Rinconada',
    'Sierra Gorda',
    'Huasco',
    'San Pablo',
    'Lago Ranco',
    'Petorca',
    'Negrete',
    'San Pedro',
    'Toltén',
    'Hualañe',
    'Quilleco',
    'Lumaco',
    'Curepto',
    'Pica',
    'San Rafael',
    'Canela',
    'Puerto Octay',
    'Hualaihué',
    'Chanco',
    'Placilla',
    'Pemuco',
    'Pelarco',
    'Quemchi',
    'Pencahue',
    'Quinchao',
    'Isla de Pascua',
    'Ercilla',
    'Pelluhue',
    'San Juan de la Costa',
    'Curarrehue',
    'Coinco',
    'Zapallar',
    'Marchihue',
    'Panquehue',
    'Los Sauces',
    'Máfil',
    'Freirina',
    'Perquenco',
    'Lolol',
    'Porvenir',
    'Licantén',
    'Navidad',
    'Cisnes',
    'María Elena',
    'Alhué',
    'Papudo',
    'Litueche',
    'Paredones',
    'Melipeuco',
    'Contulmo',
    'Alto Biobío',
    'Ránquil',
    'Treguaco',
    'Queilén',
    'Corral',
    'Alto del Carmen',
    'Ninhue',
    'Chaitén',
    'Cobquecura',
    'Chile Chico',
    'Portezuelo',
    'Paihuano',
    'Vichuquén',
    'San Fabián',
    'Río Hurtado',
    'La Higuera',
    'Empedrado',
    'Antuco',
    'Cochamó',
    'Quilaco',
    'Puqueldón',
    'Curaco de Vélez',
    'Cochrane',
    'Pumanque',
    'San Rosendo',
    'La Estrella',
    'Putre',
    'Huara',
    'Río Ibáñez',
    'Futaleufú',
    'Cabo de Hornos',
    'Guaitecas',
    'Colchane',
    'Palena',
    'Camarones',
    'Camiña',
    'Torres del Paine',
    'Primavera',
    'Juan Fernández',
    'Lago Verde',
    'San Gregorio',
    'General Lagos',
    "O'Higgins",
    'Río Verde',
    'Tortel',
    'Timaukel',
    'Ollagüe',
    'Laguna Blanca',
    'Antártica',
  ];
};
