import React, {Component} from 'react';
//import '../../../assets/css/Payment/Steps.css';
import activ1 from '../../assets/images/Pescadores/1_activo.png';
import inactiv1 from '../../assets/images/Pescadores/1_inactivo.png';

import activ2 from '../../assets/images/Pescadores/2_activo.png';
import inactiv2 from '../../assets/images/Pescadores/2_inactivo.png';

import activ3 from '../../assets/images/Pescadores/3_activo.png';
import inactiv3 from '../../assets/images/Pescadores/3_inactivo.png';


const Steps = props => {
  var step1 = '';
  var step2 = '';
  var step3 = '';
  var step1_img = inactiv1;
  var step2_img = inactiv2;
  var step3_img = inactiv3;
  switch (parseInt(props.step)) {
    case 1:
      step1 = 'selected';
      step1_img = activ1;
      break;
    case 2:
      step2 = 'selected';
      step2_img = activ2;
      break;
    case 3:
      step3 = 'selected';
      step3_img = activ3;
      break;

    default:
      console.log('error del parametro');
  }

  return (
    <div>
      
      <div id="pasosDesktop" className="grid">
        <div className="col-12">
          <ul className="pasos">
            <li className={step1}>
              <span>1</span>Datos del Asegurado<span className="deco-pasos"></span>
            </li>
            <li className={step2}>
              <span>2</span>Confirmaci&oacute;n de Datos<span className="deco-pasos"></span>
            </li>
            <li className={step3}>
              <span>3</span>Confirmaci&oacute;n de Transacci&oacute;n<span className="deco-pasos"></span>
            </li>
          </ul>
        </div>
      </div>

      <div id="menuMobile">
        <div className="contenedor_general">
          <div className="contenedor">
            <div className="pasosinicio">
              <img border="0" src={step1_img} />
            </div>
            <div className="linea_naranja">
            </div>
            <div className="pasosinter">
              <img border="0" src={step2_img} />
            </div>
            <div className="linea_naranja">
            </div>
            <div className="pasosfinal">
              <img border="0" src={step3_img} />
            </div>
          </div>
        </div>
      </div>
    </div>

  );
};

export default Steps;
