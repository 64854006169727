import React, { Component } from 'react';
import {
  Form,
  Container,
} from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import Pdf from '../../../Documents/494000157.pdf';
import Pdf2 from '../../../Documents/494000157.pdf';
import telefono from '../../../assets/images/Pescadores/icono_tel.png';
import MetlifeService from '../../../services/MetlifeDataService';
import { getErrorMessage } from '../../tools/Tools';

class InsuredForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: {},
      errors: {},
      newCustomer: null,
      disabled: 'disabled',
      condSeguro: false,
      check: '',
      token: null,
      acepta: false,
      leyoCondiciones: false,
      token_ws: null,
      disabledbutton: false,
      error: false,
    };

    this.state.newCustomer = this.props.newCustomer;

    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.aceptaCondiciones = this.aceptaCondiciones.bind(this);
    this.handleCheck = this.handleCheck.bind(this);

    var mailformat = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    this.validEmailRegex = RegExp(mailformat);

    /*
    this.validEmailRegex = RegExp(
      /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
    );
    */

    if (Object.keys(this.state.newCustomer.customer).length > 0) {
      let customer = this.state.newCustomer.customer;
      this.state.fields['nombre'] = customer.firstName;
      this.state.fields['apellidos'] = customer.fullLastName;
      this.state.fields['rut'] = customer.rutformat;
      this.state.fields['email'] = customer.email;
      this.state.fields['confEmail'] = customer.email;
      this.state.fields['commune'] = ''; //customer.commune;
      this.state.fields['celular'] = customer.phoneNumberOri;
      this.state.fields['address'] = customer.address;
      let bd = customer.birthdate.split('-');
      this.state.fields['nac_anio'] = bd[0];
      this.state.fields['nac_mes'] = bd[1];
      this.state.fields['nac_dia'] = bd[2];
      this.state.fields['region'] = ''; //customer.region;
      this.state.fields['actividad'] = customer.actividad;
    }
  }

  getAge(dob) {
    var ageDifMs = Date.now() - dob.getTime();
    var ageDate = new Date(ageDifMs);
    return Math.abs(ageDate.getUTCFullYear() - 1970) < 18 ? false : true
  }

  aceptaCondiciones() {
    if(this.state.leyoCondiciones) {
      this.setState({
        disabled: '',
        acepta: true,
      });
    } else {
      this.setState({
        disabled: '',
        leyoCondiciones: true,
      });
    }
  }

  handleValidation() {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;
    //Validacion requerido
    [
      'nombre',
      'apellidos',
      'rut',
      'email',
      'confEmail',
      'celular',
      'commune',
      'address',
      'nac_dia',
      'nac_mes',
      'nac_anio',
      'region',
      'actividad',
    ].map(campo => {
      switch (campo) {
        case 'nombre':
          if (!fields[campo]) errors[campo] = 'Ingresa tu nombre';
          break;
        case 'apellidos':
          if (!fields[campo]) errors[campo] = 'Ingresa tus apellidos';
          break;
        case 'rut':
          if (!fields[campo]) errors[campo] = 'Ingresa un Rut válido';
          else if (!(7 <= fields[campo].length <= 9))
            errors[campo] = 'Ingresa un Rut con un tamaño válido';
          break;
        case 'email':
          if (!fields[campo]) errors[campo] = 'Ingresa tu email';
          else if (!this.validEmailRegex.test(fields['email']))
            errors[campo] = 'Email no es válido';
          break;
        case 'confEmail':
          if (!fields[campo]) errors[campo] = 'Reingresa tu email';
          break;
        case 'celular':
          if (!fields[campo]) errors[campo] = 'Ingresa tu celular';
          else if (!(9 <= fields[campo].length <= 11))
            errors[campo] = 'Ingresa un celular con un tamaño válido';
          break;
        case 'commune':
          if (!fields[campo]) errors[campo] = 'Ingresa tu comuna';
          break;
        case 'address':
          if (!fields[campo]) errors[campo] = 'Ingresa tu dirección';
          break;
        case 'nac_dia':
          if (!fields[campo])
            errors['nac_dia'] = 'Ingresa tu fecha de nacimiento';
          break;
        case 'nac_mes':
          if (!fields[campo])
            errors['nac_dia'] = 'Ingresa tu fecha de nacimiento';
          break;
        case 'nac_anio':
          if (!fields[campo])
            errors['nac_dia'] = 'Ingresa tu fecha de nacimiento';
          break;
        case 'region':
          if (!fields[campo]) errors[campo] = 'Ingresa tu región';
          break;
        case 'actividad':
          if (!fields[campo]) errors[campo] = 'Ingresa tu actividad';
          break;
      }
    });
    if ('nac_anio' in fields && 'nac_mes' in fields && 'nac_dia' in fields) {
      if (
        !this.getAge(
          new Date(
            fields['nac_anio'] +
            '/' +
            fields['nac_mes'] +
            '/' +
            fields['nac_dia']
          )
        )
      ) {
        errors['nac_dia'] = 'Edad debe estar entre 18 y 78 años';
      }
    }

    // validacion de correo iguales
    if (fields['email'] != fields['confEmail']) {
      formIsValid = false;
      errors['confEmail'] = 'Por favor ingrese el mismo email';
    }
    //validar condiciones
    if (!this.state.check) errors['condSeguro'] = 'Acepte las condiciones';
    if (!this.state.leyoCondiciones) errors['condSeguro'] = 'Lea y acepte las condiciones';

    this.setState({ errors: errors });
    if (Object.keys(errors).length > 0) formIsValid = false;
    return formIsValid;
  }

  handleFormSubmit(e) {
    // Form submission logic
    if (this.state.disabledbutton) {
      return;
    }
    this.setState({ disabledbutton: true });
    this.setState({ error: true });
    e.preventDefault();
    if (this.handleValidation()) {
      let rut = this.state.fields['rut'];
      let part1 = rut.slice(0, -10);
      let part2 = rut.slice(-9, -6);
      let part3 = rut.slice(-5, -2);

      const customer = {
        firstName: this.state.fields['nombre'],
        fullLastName: this.state.fields['apellidos'],
        lastName: this.state.fields['apellidos'],
        numberrut: this.state.newCustomer.customer.numberrut,
        rut: part1 + part2 + part3,
        rutformat: this.state.fields['rut'],
        dvRut: rut.slice(-1),
        birthdate:
          this.state.fields['nac_anio'] +
          '-' +
          this.state.fields['nac_mes'] +
          '-' +
          this.state.fields['nac_dia'],
        email: this.state.fields['email'],
        phoneNumber: '56' + this.state.fields['celular'],
        phoneNumberOri: this.state.fields['celular'],
        commune: this.state.fields['commune'],
        comuna: this.state.fields['commune'],
        address: this.state.fields['address'],
        region: this.state.fields['region'],
        actividad: this.state.fields['actividad'],
        activity: this.state.fields['actividad'],
      };
      this.state.newCustomer.customer = customer;
      //homologando cambios de llamadas de servicios.
      this.state.newCustomer.customer['smsCode'] = '000000';
      this.state.newCustomer.product['code'] = 'app';
      this.state.newCustomer.product.planId = this.state.newCustomer.product.id;
      let lastname = this.state.newCustomer.customer.fullLastName.split(' ');
      this.state.newCustomer.customer.lastName = lastname[0];
      this.state.newCustomer.customer.secondLastName = lastname
        .slice(1)
        .join(' ');

      //Ini mover region comuna a insured
      let region = this.props.location.state.newCustomer.customer.region;
      let comuna = this.props.location.state.newCustomer.customer.comuna;
      let regionName = this.props.location.state.newCustomer.customer.region;
      switch (region) {
        case '1':
          regionName = 'Región de Tarapacá';
          break;
        case '2':
          regionName = 'Región de Antofagasta';
          break;
        case '3':
          regionName = 'Región de Atacama';
          break;
        case '4':
          regionName = 'Región de Coquimbo';
          break;
        case '5':
          regionName = 'Región de Valparaíso';
          break;
        case '6':
          regionName = "Región del Libertador General Bernardo O'Higgins";
          break;
        case '7':
          regionName = 'Región del Maule';
          break;
        case '8':
          regionName = 'Región del Bío-Bío';
          break;
        case '9':
          regionName = 'Región de la Araucanía';
          break;
        case '10':
          regionName = 'Región de Los Lagos';
          break;
        case '11':
          regionName = 'Región de Aysén del General Carlos Ibáñez del Campo';
          break;
        case '12':
          regionName = 'Región de Magallanes y la Antártica Chilena';
          break;
        case '13':
          regionName = 'Región Metropolitana';
          break;
        case '14':
          regionName = 'Región de Los Ríos';
          break;
        case '15':
          regionName = 'Región de Arica y Parinacota';
          break;
      }
      this.state.newCustomer.customer.commune = comuna + ', ' + regionName;
      //Fin mover region comuna a insured

      MetlifeService.createOrder(
        this.state.newCustomer.token,
        this.state.newCustomer
      )
        .then(res => {
          this.state.newCustomer.payment['sessionId'] =
            res.data.payment.sessionId;
          this.props.history.push({
            pathname: '/previeworder',
            state: { newCustomer: this.state.newCustomer },
          });
          //fin de homologacion
        })
        .catch(err => {
          //alert('fail');
          console.log(err);
          this.setState({ error: true });
          if (err.response) {
            this.setState({
              errorMessage: getErrorMessage(err.response.data.errorCode),
            });
          }
          this.setState({ disabledbutton: false });
        });
    } else {
      this.setState({ disabledbutton: false });
    }
  }

  handleRut(field, e) {
    let fields = this.state.fields;
    let labelRut = e.target.value;

    labelRut = labelRut.replace(/-/g, '');
    labelRut = labelRut.replace(/\./g, '');
    labelRut = labelRut.replace(/\W/g, '');
    let numberRut = labelRut;
    if ('rut' in this.state.fields) {
      let label;
      //console.log(labelRut);
      //console.log(labelRut.length);
      if (labelRut.length >= 2) {
        labelRut = labelRut.slice(0, -1) + '-' + labelRut.slice(-1);
      }
      //console.log(labelRut);
      //console.log(labelRut.length);
      if (labelRut.length >= 6) {
        labelRut = labelRut.slice(0, -5) + '.' + labelRut.slice(-5);
      }
      //console.log(labelRut);
      //console.log(labelRut.length);
      if (labelRut.length >= 10) {
        labelRut = labelRut.slice(0, -9) + '.' + labelRut.slice(-9);
      }
      //console.log(labelRut);
      //console.log(labelRut.length);
      //labelRut = label;
    }

    fields[field] = labelRut;
    fields['numberrut'] = numberRut;
    this.setState({fields});
  }


  handleChange(field, e) {
    let fields = this.state.fields;
    let campo = e.target.value;
    if (field == 'nombre' || field == 'apellidos') {
      campo = campo.replace(/[^a-zA-Z áéíóúÁÉÍÓÚñÑ]/g, '');
    } else if (field == 'celular') {
      campo = campo.replace(/[^0-9]/g, '');
    } else if (field == 'address') {
      campo = campo.replace(/[^0-9a-zA-Z \.\,\-\#áéíóúÁÉÍÓÚñÑ]/g, '');
    }
    fields[field] = campo;

    if (field == 'region') {
      fields['commune'] = '';
    }

    this.setState({ fields });
  }

  handleCheck(e) {
    if(this.state.leyoCondiciones) {
      let check = this.state.check;
      check = e.target.checked;
      this.setState({ check });
    } else {
      window.open(Pdf2, "_blank");
    }
  }

  render() {
    const initDate = 1935;
    const current = new Date();
    const endDate = current.getFullYear() - 18
    const cantAnios = endDate - initDate;
    const years = Array(cantAnios);

    for (let index = 0; index <= cantAnios; index++) {
      years[index] = 1935 + index
    }
    return (
      <Container>
        <div className="grid">
          <div className="col-12">
            <Form id="form" name="form" onSubmit={this.handleFormSubmit}>
              <p></p>
              <div className="row">
                <div className="col-6">
                  <div className="form-general">
                    <div className="form-group has-feedback">
                      <label className="control-label">Nombre</label>
                      <input
                        id="21-33-363-66-NOMBRE"
                        name="nombre"
                        placeholder="Ej.: Juan"
                        className="nuwField0 nuwField0_required NOMBREASEGURADO"
                        type="text"
                        value={this.state.fields['nombre'] || ''}
                        onChange={this.handleChange.bind(this, 'nombre')}
                        size="20"
                        maxLength="20"
                      />
                      {this.state.errors['nombre'] ? (
                        <span style={{ color: '#a94442' }}>
                          {this.state.errors['nombre']}
                        </span>
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <div className="form-general">
                    <div className="form-group has-feedback">
                      <label className="control-label">Apellidos</label>
                      <input
                        id="21-33-364-67-APELLIDOS"
                        name="apellidos"
                        placeholder="Ej.: Rodriguez Pino"
                        className="nuwField1 nuwField1_required APELLIDOSASEGURADO"
                        type="text"
                        onChange={this.handleChange.bind(this, 'apellidos')}
                        value={this.state.fields['apellidos'] || ''}
                        size="50"
                        maxLength="50"
                      />
                      {this.state.errors['apellidos'] ? (
                        <span style={{ color: '#a94442' }}>
                          {this.state.errors['apellidos']}
                        </span>
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div style={{ clear: 'both' }}></div>
              <div className="row">
                <div className="col-6">
                  <div className="form-general">
                    <div className="form-group has-feedback">
                      <label className="control-label">RUT</label>
                      <input
                        id="21-33-365-63-RUT"
                        name="rut"
                        placeholder="Ej.:16.301.128-k"
                        className="nuwField2 nuwField2_required RUTASEGURADO"
                        type="text"
                        value={this.state.fields['rut'] || ''}
                        size="11"
                        maxLength="13"
                        onChange={this.handleRut.bind(this, 'rut')}
                      />
                      {this.state.errors['rut'] ? (
                        <span style={{ color: '#a94442' }}>
                          {this.state.errors['rut']}
                        </span>
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <div className="form-general">
                    <div className="form-group has-feedback">
                      <label className="control-label">
                        Fecha de Nacimiento
                      </label>
                      <div className="dateGroup">
                        <div id="nuwField3BithOfDatehidden"></div>
                        <div className="variation dia">
                          <Form.Control
                            as="select"
                            className="select subir-fecha dateDias form-control nuwField3_required"
                            id="nuwField3DateDias"
                            name="nac_dia"
                            onChange={this.handleChange.bind(this, 'nac_dia')}
                            value={this.state.fields['nac_dia'] || ''}
                            custom>
                            <option value="">D&iacute;a</option>
                            <option value="01">01</option>
                            <option value="02">02</option>
                            <option value="03">03</option>
                            <option value="04">04</option>
                            <option value="05">05</option>
                            <option value="06">06</option>
                            <option value="07">07</option>
                            <option value="08">08</option>
                            <option value="09">09</option>
                            <option value="10">10</option>
                            <option value="11">11</option>
                            <option value="12">12</option>
                            <option value="13">13</option>
                            <option value="14">14</option>
                            <option value="15">15</option>
                            <option value="16">16</option>
                            <option value="17">17</option>
                            <option value="18">18</option>
                            <option value="19">19</option>
                            <option value="20">20</option>
                            <option value="21">21</option>
                            <option value="22">22</option>
                            <option value="23">23</option>
                            <option value="24">24</option>
                            <option value="25">25</option>
                            <option value="26">26</option>
                            <option value="27">27</option>
                            <option value="28">28</option>
                            <option value="29">29</option>
                            <option value="30">30</option>
                            <option value="31">31</option>
                          </Form.Control>
                        </div>
                        <div className="variation mes">
                          <Form.Control
                            as="select"
                            className="select dateMes subir-fecha form-control nuwField3_required"
                            id="nuwField3DateMes"
                            name="nac_mes"
                            onChange={this.handleChange.bind(this, 'nac_mes')}
                            value={this.state.fields['nac_mes'] || ''}
                            custom>
                            <option value="">Mes</option>
                            <option value="01">Enero</option>
                            <option value="02">Febrero</option>
                            <option value="03">Marzo</option>
                            <option value="04">Abril</option>
                            <option value="05">Mayo</option>
                            <option value="06">Junio</option>
                            <option value="07">Julio</option>
                            <option value="08">Agosto</option>
                            <option value="09">Septiembre</option>
                            <option value="10">Octubre</option>
                            <option value="11">Noviembre</option>
                            <option value="12">Diciembre</option>
                          </Form.Control>
                        </div>
                        <div className="variation ano">
                          <Form.Control
                            as="select"
                            className="select dateAnno form-control subir-fecha nuwField3_required"
                            id="nuwField3DateAgno"
                            name="nac_anio"
                            onChange={this.handleChange.bind(this, 'nac_anio')}
                            value={this.state.fields['nac_anio'] || ''}
                            custom>
                            <option value="">A&ntilde;o</option>
                            {years.map((y, i) =>
                              <option key={i} value={y}>{y}</option>
                            )}
                          </Form.Control>
                        </div>
                        {this.state.errors['nac_dia'] ? (
                          <span style={{ color: '#a94442' }}>
                            {this.state.errors['nac_dia']}
                          </span>
                        ) : (
                          ''
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div style={{ clear: 'both' }}></div>
              <div className="row">
                <div className="col-6">
                  <div className="form-general">
                    <div className="form-group has-feedback">
                      <label className="control-label">Email</label>
                      <input
                        id="21-33-366-68-EMAIL"
                        name="email"
                        placeholder="juanrodriguez@micorreo.cl"
                        className="nuwField4 nuwField4_required EMAILASEGURADO"
                        type="text"
                        onChange={this.handleChange.bind(this, 'email')}
                        value={this.state.fields['email'] || ''}
                        size="100"
                        maxLength="100"
                      />
                      {this.state.errors['email'] ? (
                        <span style={{ color: '#a94442' }}>
                          {this.state.errors['email']}
                        </span>
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <div className="form-general">
                    <div className="form-group has-feedback">
                      <label className="control-label">Confirmar Email</label>
                      <input
                        id="21-33-366-68-EMAIL_copy"
                        placeholder="juanrodriguez@micorreo.cl"
                        className="nuwField4_copy COPY nuwField4_requiredCopy"
                        type="text"
                        onChange={this.handleChange.bind(this, 'confEmail')}
                        value={this.state.fields['confEmail'] || ''}
                        size="100"
                        maxLength="100"
                      />
                      {this.state.errors['confEmail'] ? (
                        <div>
                          <span style={{ color: '#a94442' }}>
                            {this.state.errors['confEmail']}
                          </span>
                        </div>
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div style={{ clear: 'both' }}></div>
              <div className="row">
                <div className="col-6">
                  <div className="form-general">
                    <div className="form-group has-feedback">
                      <label className="control-label">Celular</label>
                      <input
                        id="21-33-367-69-TELEFONOMOVIL"
                        name="celular"
                        placeholder="994871536"
                        className="nuwField5 nuwField5_required TELEFONOMOVILASEGURADO"
                        type="text"
                        onChange={this.handleChange.bind(this, 'celular')}
                        value={this.state.fields['celular'] || ''}
                        size="10"
                        maxLength="9"
                      />
                      {this.state.errors['celular'] ? (
                        <span style={{ color: '#a94442' }}>
                          {this.state.errors['celular']}
                        </span>
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <div className="form-general">
                    <div className="form-group has-feedback">
                      <label className="control-label">Actividad</label>
                      <div className="variation">
                        <Form.Control
                          as="select"
                          className="form-control seleccion select nuwField6_required"
                          id="ACTIVIDADPESCADOR"
                          name="actividad"
                          onChange={this.handleChange.bind(this, 'actividad')}
                          value={this.state.fields['actividad'] || ''}
                          custom>
                          <option value="">Selecciona actividad</option>
                          <option id="Pescador" value="Pescador">
                            Pescador
                          </option>
                          <option id="Buzo" value="Buzo">
                            Buzo
                          </option>
                          <option id="Alguero" value="Alguero">
                            Alguero
                          </option>
                          <option id="Acuicultor" value="Acuicultor">
                            Acuicultor
                          </option>
                          <option id="Tripulante" value="Tripulante">
                            Tripulante
                          </option>
                          <option
                            id="Otras actividades del sector pesquero"
                            value="Otras actividades del sector pesquero">
                            Otras actividades del sector pesquero
                          </option>
                        </Form.Control>
                      </div>
                      {this.state.errors['actividad'] ? (
                        <span style={{ color: '#a94442' }}>
                          {this.state.errors['actividad']}
                        </span>
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div style={{ clear: 'both' }}></div>
              <div className="row">
                <div className="col-6">
                  <div className="form-general">
                    <div className="form-group has-feedback">
                      <label className="control-label">Región</label>
                      <div className="variation">
                        <Form.Control
                          as="select"
                          className="form-control seleccion nuwField7_required"
                          id="REGION"
                          name="region"
                          onChange={this.handleChange.bind(this, 'region')}
                          value={this.state.fields['region'] || ''}
                          custom>
                          <option value="">Selecciona una región</option>
                          <option id="1" value="1">
                            Región de Tarapacá
                          </option>
                          <option id="2" value="2">
                            Región de Antofagasta
                          </option>
                          <option id="3" value="3">
                            Región de Atacama
                          </option>
                          <option id="4" value="4">
                            Región de Coquimbo
                          </option>
                          <option id="5" value="5">
                            Región de Valparaíso
                          </option>
                          <option id="6" value="6">
                            Región del Libertador General Bernardo O'Higgins
                          </option>
                          <option id="7" value="7">
                            Región del Maule
                          </option>
                          <option id="8" value="8">
                            Región del Bío-Bío
                          </option>
                          <option id="9" value="9">
                            Región de la Araucanía
                          </option>
                          <option id="10" value="10">
                            Región de Los Lagos
                          </option>
                          <option id="11" value="11">
                            Región de Aysén del General Carlos Ibáñez del Campo
                          </option>
                          <option id="12" value="12">
                            Región de Magallanes y la Antártica Chilena
                          </option>
                          <option id="13" value="13">
                            Región Metropolitana
                          </option>
                          <option id="14" value="14">
                            Región de Los Ríos
                          </option>
                          <option id="15" value="15">
                            Región de Arica y Parinacota
                          </option>
                        </Form.Control>
                      </div>
                      {this.state.errors['region'] ? (
                        <span style={{ color: '#a94442' }}>
                          {this.state.errors['region']}
                        </span>
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <div className="form-general">
                    <div className="form-group has-feedback">
                      <label className="control-label">Comuna</label>
                      <div className="variation">
                        {this.state.fields['region'] == 1 ? (
                          <Form.Control
                            as="select"
                            className="form-control seleccion comunas nuwField8_required"
                            id="COMUNA"
                            name="commune"
                            onChange={this.handleChange.bind(this, 'commune')}
                            value={this.state.fields['commune'] || ''}
                            custom>
                            <option value="">Selecciona una comuna</option>
                            <option value="Colchane">Colchane</option>
                            <option value="Camiña">Camiña</option>
                            <option value="Alto Hospicio">Alto Hospicio</option>
                            <option value="Iquique">Iquique</option>
                            <option value="Pozo Almonte">Pozo Almonte</option>
                            <option value="Pica">Pica</option>
                            <option value="Huara">Huara</option>
                          </Form.Control>
                        ) : this.state.fields['region'] == 2 ? (
                          <Form.Control
                            as="select"
                            className="form-control seleccion comunas nuwField8_required"
                            id="COMUNA"
                            name="commune"
                            onChange={this.handleChange.bind(this, 'commune')}
                            value={this.state.fields['commune'] || ''}
                            custom>
                            <option value="">Selecciona una comuna</option>
                            <option value="Calama">Calama</option>
                            <option value="Antofagasta">Antofagasta</option>
                            <option value="Tocopilla">Tocopilla</option>
                            <option value="Taltal">Taltal</option>
                            <option value="Sierra Gorda">Sierra Gorda</option>
                            <option value="San Pedro Atacama">
                              San Pedro Atacama
                            </option>
                            <option value="Ollague">Ollague</option>
                            <option value="Mejillones">Mejillones</option>
                            <option value="María Elena">María Elena</option>
                          </Form.Control>
                        ) : this.state.fields['region'] == 3 ? (
                          <Form.Control
                            as="select"
                            className="form-control seleccion comunas nuwField8_required"
                            id="COMUNA"
                            name="commune"
                            onChange={this.handleChange.bind(this, 'commune')}
                            value={this.state.fields['commune'] || ''}
                            custom>
                            <option value="">Selecciona una comuna</option>
                            <option value="Alto Del Carmen">
                              Alto Del Carmen
                            </option>
                            <option value="Huasco">Huasco</option>
                            <option value="Copiapó">Copiapó</option>
                            <option value="Chañaral">Chañaral</option>
                            <option value="Vallenar">Vallenar</option>
                            <option value="Freirina">Freirina</option>
                            <option value="Tierra Amarilla">
                              Tierra Amarilla
                            </option>
                            <option value="Caldera">Caldera</option>
                            <option value="Diego De Almagro">
                              Diego De Almagro
                            </option>
                          </Form.Control>
                        ) : this.state.fields['region'] == 4 ? (
                          <Form.Control
                            as="select"
                            className="form-control seleccion comunas nuwField8_required"
                            id="COMUNA"
                            name="commune"
                            onChange={this.handleChange.bind(this, 'commune')}
                            value={this.state.fields['commune'] || ''}
                            custom>
                            <option value="">Selecciona una comuna</option>
                            <option value="Punitaqui">Punitaqui</option>
                            <option value="Río Hurtado">Río Hurtado</option>
                            <option value="Coquimbo">Coquimbo</option>
                            <option value="Combarbala">Combarbala</option>
                            <option value="Canela">Canela</option>
                            <option value="Vicuña">Vicuña</option>
                            <option value="Paihuano">Paihuano</option>
                            <option value="Monte Patria">Monte Patria</option>
                            <option value="Salamanca">Salamanca</option>
                            <option value="Los Vilos">Los Vilos</option>
                            <option value="La Serena">La Serena</option>
                            <option value="La Higuera">La Higuera</option>
                            <option value="Andacollo">Andacollo</option>
                            <option value="Illapel">Illapel</option>
                          </Form.Control>
                        ) : this.state.fields['region'] == 5 ? (
                          <Form.Control
                            as="select"
                            className="form-control seleccion comunas nuwField8_required"
                            id="COMUNA"
                            name="commune"
                            onChange={this.handleChange.bind(this, 'commune')}
                            value={this.state.fields['commune'] || ''}
                            custom>
                            <option value="">Selecciona una comuna</option>
                            <option value="Algarrobo">Algarrobo</option>
                            <option value="Cabildo">Cabildo</option>
                            <option value="Calle Larga">Calle Larga</option>
                            <option value="Cartagena">Cartagena</option>
                            <option value="Casablanca">Casablanca</option>
                            <option value="Catemu">Catemu</option>
                            <option value="Concón">Concón</option>
                            <option value="El Quisco">El Quisco</option>
                            <option value="El Tabo">El Tabo</option>
                            <option value="Hijuelas">Hijuelas</option>
                            <option value="Isla De Pascua">
                              Isla De Pascua
                            </option>
                            <option value="Juan Fernández">
                              Juan Fernández
                            </option>
                            <option value="La Calera">La Calera</option>
                            <option value="La Cruz">La Cruz</option>
                            <option value="La Ligua">La Ligua</option>
                            <option value="Limache">Limache</option>
                            <option value="Llay Llay">Llay Llay</option>
                            <option value="Los Andes">Los Andes</option>
                            <option value="Nogales">Nogales</option>
                            <option value="Olmué">Olmué</option>
                            <option value="Panquehue">Panquehue</option>
                            <option value="Papudo">Papudo</option>
                            <option value="Petorca">Petorca</option>
                            <option value="Puchuncaví">Puchuncaví</option>
                            <option value="Putaendo">Putaendo</option>
                            <option value="Quillota">Quillota</option>
                            <option value="Quilpué">Quilpué</option>
                            <option value="Quintero">Quintero</option>
                            <option value="Rinconada">Rinconada</option>
                            <option value="San Antonio">San Antonio</option>
                            <option value="San Esteban">San Esteban</option>
                            <option value="San Felipe">San Felipe</option>
                            <option value="Santa María">Santa María</option>
                            <option value="Santo Domingo">Santo Domingo</option>
                            <option value="Valparaíso">Valparaíso</option>
                            <option value="Villa Alemana">Villa Alemana</option>
                            <option value="Viña Del Mar">Viña Del Mar</option>
                            <option value="Zapallar">Zapallar</option>
                          </Form.Control>
                        ) : this.state.fields['region'] == 6 ? (
                          <Form.Control
                            as="select"
                            className="form-control seleccion comunas nuwField8_required"
                            id="COMUNA"
                            name="commune"
                            onChange={this.handleChange.bind(this, 'commune')}
                            value={this.state.fields['commune'] || ''}
                            custom>
                            <option value="">Selecciona una comuna</option>
                            <option value="Chépica">Chépica</option>
                            <option value="Chimbarongo">Chimbarongo</option>
                            <option value="Codegua">Codegua</option>
                            <option value="Coinco">Coinco</option>
                            <option value="Coltauco">Coltauco</option>
                            <option value="Doñihue">Doñihue</option>
                            <option value="Graneros">Graneros</option>
                            <option value="La Estrella">La Estrella</option>
                            <option value="Las Cabras">Las Cabras</option>
                            <option value="Litueche">Litueche</option>
                            <option value="Lolol">Lolol</option>
                            <option value="Machalí">Machalí</option>
                            <option value="Malloa">Malloa</option>
                            <option value="Marchigue">Marchigue</option>
                            <option value="Nancagua">Nancagua</option>
                            <option value="Navidad">Navidad</option>
                            <option value="Olivar">Olivar</option>
                            <option value="Palmilla">Palmilla</option>
                            <option value="Paredones">Paredones</option>
                            <option value="Peralillo">Peralillo</option>
                            <option value="Peumo">Peumo</option>
                            <option value="Pichidegua">Pichidegua</option>
                            <option value="Pichilemu">Pichilemu</option>
                            <option value="Placilla">Placilla</option>
                            <option value="Pumanque">Pumanque</option>
                            <option value="Quinta De Tilcoco">
                              Quinta De Tilcoco
                            </option>
                            <option value="Rancagua">Rancagua</option>
                            <option value="Rengo">Rengo</option>
                            <option value="Requinoa">Requinoa</option>
                            <option value="San Fernando">San Fernando</option>
                            <option value="San Vicente">San Vicente</option>
                            <option value="Santa Cruz">Santa Cruz</option>
                            <option value="Sn. Fco. De Mostazal">
                              Sn. Fco. De Mostazal
                            </option>
                          </Form.Control>
                        ) : this.state.fields['region'] == 7 ? (
                          <Form.Control
                            as="select"
                            className="form-control seleccion comunas nuwField8_required"
                            id="COMUNA"
                            name="commune"
                            onChange={this.handleChange.bind(this, 'commune')}
                            value={this.state.fields['commune'] || ''}
                            custom>
                            <option value="">Selecciona una comuna</option>
                            <option value="Cauquenes">Cauquenes</option>
                            <option value="Chanco">Chanco</option>
                            <option value="Colbún">Colbún</option>
                            <option value="Constitución">Constitución</option>
                            <option value="Curepto">Curepto</option>
                            <option value="Curicó">Curicó</option>
                            <option value="Empedrado">Empedrado</option>
                            <option value="Hualañe">Hualañe</option>
                            <option value="Licantén">Licantén</option>
                            <option value="Linares">Linares</option>
                            <option value="Longaví">Longaví</option>
                            <option value="Maule">Maule</option>
                            <option value="Molina">Molina</option>
                            <option value="Parral">Parral</option>
                            <option value="Pelarco">Pelarco</option>
                            <option value="Pelluhue">Pelluhue</option>
                            <option value="Pencahue">Pencahue</option>
                            <option value="Rauco">Rauco</option>
                            <option value="Retiro">Retiro</option>
                            <option value="Río Claro">Río Claro</option>
                            <option value="Romeral">Romeral</option>
                            <option value="Sagrada Familia">
                              Sagrada Familia
                            </option>
                            <option value="San Clemente">San Clemente</option>
                            <option value="San Javier">San Javier</option>
                            <option value="San Rafael">San Rafael</option>
                            <option value="Talca">Talca</option>
                            <option value="Teno">Teno</option>
                            <option value="Vichuquén">Vichuquén</option>
                            <option value="Villa Alegre">Villa Alegre</option>
                            <option value="Yerbas Buenas">Yerbas Buenas</option>
                          </Form.Control>
                        ) : this.state.fields['region'] == 8 ? (
                          <Form.Control
                            as="select"
                            className="form-control seleccion comunas nuwField8_required"
                            id="COMUNA"
                            name="commune"
                            onChange={this.handleChange.bind(this, 'commune')}
                            value={this.state.fields['commune'] || ''}
                            custom>
                            <option value="">Selecciona una comuna</option>
                            <option value="Alto Bíobío">Alto Bíobío</option>
                            <option value="Antuco">Antuco</option>
                            <option value="Arauco">Arauco</option>
                            <option value="Bulnes">Bulnes</option>
                            <option value="Cabrero">Cabrero</option>
                            <option value="Cañete">Cañete</option>
                            <option value="Chiguayante">Chiguayante</option>
                            <option value="Chillán">Chillán</option>
                            <option value="Chillán Viejo">Chillán Viejo</option>
                            <option value="Cobquecura">Cobquecura</option>
                            <option value="Coelemu">Coelemu</option>
                            <option value="Coihueco">Coihueco</option>
                            <option value="Concepción">Concepción</option>
                            <option value="Contulmo">Contulmo</option>
                            <option value="Coronel">Coronel</option>
                            <option value="Curanilahue">Curanilahue</option>
                            <option value="El Carmen">El Carmen</option>
                            <option value="Florida">Florida</option>
                            <option value="Hualpén">Hualpén</option>
                            <option value="Hualqui">Hualqui</option>
                            <option value="Laja">Laja</option>
                            <option value="Lebu">Lebu</option>
                            <option value="Los Álamos">Los Álamos</option>
                            <option value="Los Ángeles">Los Ángeles</option>
                            <option value="Lota">Lota</option>
                            <option value="Mulchén">Mulchén</option>
                            <option value="Nacimiento">Nacimiento</option>
                            <option value="Negrete">Negrete</option>
                            <option value="Ninhue">Ninhue</option>
                            <option value="Ñiquén">Ñiquén</option>
                            <option value="Pemuco">Pemuco</option>
                            <option value="Penco">Penco</option>
                            <option value="Pinto">Pinto</option>
                            <option value="Portezuelo">Portezuelo</option>
                            <option value="Quilaco">Quilaco</option>
                            <option value="Quilleco">Quilleco</option>
                            <option value="Quillón">Quillón</option>
                            <option value="Quirihue">Quirihue</option>
                            <option value="Ránquil">Ránquil</option>
                            <option value="San Carlos">San Carlos</option>
                            <option value="San Fabián">San Fabián</option>
                            <option value="San Ignacio">San Ignacio</option>
                            <option value="San Nicolás">San Nicolás</option>
                            <option value="San Pedro De La Paz">
                              San Pedro De La Paz
                            </option>
                            <option value="San Rosendo">San Rosendo</option>
                            <option value="Santa Bárbara">Santa Bárbara</option>
                            <option value="Santa Juana">Santa Juana</option>
                            <option value="Talcahuano">Talcahuano</option>
                            <option value="Tirúa">Tirúa</option>
                            <option value="Tomé">Tomé</option>
                            <option value="Trehuaco">Trehuaco</option>
                            <option value="Tucapel">Tucapel</option>
                            <option value="Yumbel">Yumbel</option>
                            <option value="Yungay">Yungay</option>
                          </Form.Control>
                        ) : this.state.fields['region'] == 9 ? (
                          <Form.Control
                            as="select"
                            className="form-control seleccion comunas nuwField8_required"
                            id="COMUNA"
                            name="commune"
                            onChange={this.handleChange.bind(this, 'commune')}
                            value={this.state.fields['commune'] || ''}
                            custom>
                            <option value="">Selecciona una comuna</option>
                            <option value="Angol">Angol</option>
                            <option value="Carahue">Carahue</option>
                            <option value="Cholchol">Cholchol</option>
                            <option value="Collipulli">Collipulli</option>
                            <option value="Cunco">Cunco</option>
                            <option value="Curacautín">Curacautín</option>
                            <option value="Curarrehue">Curarrehue</option>
                            <option value="Ercilla">Ercilla</option>
                            <option value="Freire">Freire</option>
                            <option value="Galvarino">Galvarino</option>
                            <option value="Gorbea">Gorbea</option>
                            <option value="Lautaro">Lautaro</option>
                            <option value="Loncoche">Loncoche</option>
                            <option value="Lonquimay">Lonquimay</option>
                            <option value="Los Sauces">Los Sauces</option>
                            <option value="Lumaco">Lumaco</option>
                            <option value="Melipeuco">Melipeuco</option>
                            <option value="Nueva Imperial">
                              Nueva Imperial
                            </option>
                            <option value="Padre Las Casas">
                              Padre Las Casas
                            </option>
                            <option value="Perquenco">Perquenco</option>
                            <option value="Pitrufquén">Pitrufquén</option>
                            <option value="Pucón">Pucón</option>
                            <option value="Purén">Purén</option>
                            <option value="Renaico">Renaico</option>
                            <option value="Saavedra">Saavedra</option>
                            <option value="Temuco">Temuco</option>
                            <option value="Teodoro Schmidt">
                              Teodoro Schmidt
                            </option>
                            <option value="Toltén">Toltén</option>
                            <option value="Traiguén">Traiguén</option>
                            <option value="Victoria">Victoria</option>
                            <option value="Vilcún">Vilcún</option>
                            <option value="Villarrica">Villarrica</option>
                          </Form.Control>
                        ) : this.state.fields['region'] == 10 ? (
                          <Form.Control
                            as="select"
                            className="form-control seleccion comunas nuwField8_required"
                            id="COMUNA"
                            name="commune"
                            onChange={this.handleChange.bind(this, 'commune')}
                            value={this.state.fields['commune'] || ''}
                            custom>
                            <option value="">Selecciona una comuna</option>
                            <option value="Ancud">Ancud</option>
                            <option value="Calbuco">Calbuco</option>
                            <option value="Castro">Castro</option>
                            <option value="Chaitén">Chaitén</option>
                            <option value="Chonchi">Chonchi</option>
                            <option value="Cochamó">Cochamó</option>
                            <option value="Curaco De Vélez">
                              Curaco De Vélez
                            </option>
                            <option value="Dalcahue">Dalcahue</option>
                            <option value="Fresia">Fresia</option>
                            <option value="Frutillar">Frutillar</option>
                            <option value="Futaleufú">Futaleufú</option>
                            <option value="Hualaihué">Hualaihué</option>
                            <option value="Llanquihue">Llanquihue</option>
                            <option value="Los Muermos">Los Muermos</option>
                            <option value="Maullín">Maullín</option>
                            <option value="Osorno">Osorno</option>
                            <option value="Palena">Palena</option>
                            <option value="Puerto Montt">Puerto Montt</option>
                            <option value="Puerto Octay">Puerto Octay</option>
                            <option value="Puerto Varas">Puerto Varas</option>
                            <option value="Puqueldón">Puqueldón</option>
                            <option value="Purranque">Purranque</option>
                            <option value="Puyehue">Puyehue</option>
                            <option value="Queilén">Queilén</option>
                            <option value="Quellón">Quellón</option>
                            <option value="Quemchi">Quemchi</option>
                            <option value="Quinchao">Quinchao</option>
                            <option value="Río Negro">Río Negro</option>
                            <option value="San Juan De La Costa">
                              San Juan De La Costa
                            </option>
                            <option value="San Pablo">San Pablo</option>
                          </Form.Control>
                        ) : this.state.fields['region'] == 11 ? (
                          <Form.Control
                            as="select"
                            className="form-control seleccion comunas nuwField8_required"
                            id="COMUNA"
                            name="commune"
                            onChange={this.handleChange.bind(this, 'commune')}
                            value={this.state.fields['commune'] || ''}
                            custom>
                            <option value="">Selecciona una comuna</option>
                            <option value="Aysén">Aysén</option>
                            <option value="Chile Chico">Chile Chico</option>
                            <option value="Cisnes">Cisnes</option>
                            <option value="Cochrane">Cochrane</option>
                            <option value="Coyhaique">Coyhaique</option>
                            <option value="Guaitecas">Guaitecas</option>
                            <option value="Lago Verde">Lago Verde</option>
                            <option value="Ohiggins">Ohiggins</option>
                            <option value="Río Ibañez">Río Ibañez</option>
                          </Form.Control>
                        ) : this.state.fields['region'] == 12 ? (
                          <Form.Control
                            as="select"
                            className="form-control seleccion comunas nuwField8_required"
                            id="COMUNA"
                            name="commune"
                            onChange={this.handleChange.bind(this, 'commune')}
                            value={this.state.fields['commune'] || ''}
                            custom>
                            <option value="">Selecciona una comuna</option>
                            <option value="Cabo De Hornos">
                              Cabo De Hornos
                            </option>
                            <option value="Laguna Blanca">Laguna Blanca</option>
                            <option value="Natales">Natales</option>
                            <option value="Porvenir">Porvenir</option>
                            <option value="Primavera">Primavera</option>
                            <option value="Punta Arenas">Punta Arenas</option>
                            <option value="Río Verde">Río Verde</option>
                            <option value="San Gregorio">San Gregorio</option>
                            <option value="Timaukel">Timaukel</option>
                            <option value="Torres Del Paine">
                              Torres Del Paine
                            </option>
                          </Form.Control>
                        ) : this.state.fields['region'] == 13 ? (
                          <Form.Control
                            as="select"
                            className="form-control seleccion comunas nuwField8_required"
                            id="COMUNA"
                            name="commune"
                            onChange={this.handleChange.bind(this, 'commune')}
                            value={this.state.fields['commune'] || ''}
                            custom>
                            <option value="">Selecciona una comuna</option>
                            <option value="Alhué">Alhué</option>
                            <option value="Buin">Buin</option>
                            <option value="Calera De Tango">
                              Calera De Tango
                            </option>
                            <option value="Curacaví">Curacaví</option>
                            <option value="El Monte">El Monte</option>
                            <option value="Isla De Maipo">Isla De Maipo</option>
                            <option value="María Pinto">María Pinto</option>
                            <option value="Melipilla">Melipilla</option>
                            <option value="Paine">Paine</option>
                            <option value="Peñaflor">Peñaflor</option>
                            <option value="San José Maipo">
                              San José Maipo
                            </option>
                            <option value="Padre Hurtado">Padre Hurtado</option>
                            <option value="Pedro Aguirre Cerda">
                              Pedro Aguirre Cerda
                            </option>
                            <option value="Peñalolén">Peñalolén</option>
                            <option value="Pirque">Pirque</option>
                            <option value="Providencia">Providencia</option>
                            <option value="Pudahuel">Pudahuel</option>
                            <option value="Puente Alto">Puente Alto</option>
                            <option value="Quilicura">Quilicura</option>
                            <option value="Quinta Normal">Quinta Normal</option>
                            <option value="Recoleta">Recoleta</option>
                            <option value="Renca">Renca</option>
                            <option value="San Bernardo">San Bernardo</option>
                            <option value="San Joaquín">San Joaquín</option>
                            <option value="San Miguel">San Miguel</option>
                            <option value="San Pedro">San Pedro</option>
                            <option value="San Ramón">San Ramón</option>
                            <option value="Santiago">Santiago</option>
                            <option value="Vitacura">Vitacura</option>
                            <option value="Cerrillos">Cerrillos</option>
                            <option value="Cerro Navia">Cerro Navia</option>
                            <option value="Colina">Colina</option>
                            <option value="Conchalí">Conchalí</option>
                            <option value="El Bosque">El Bosque</option>
                            <option value="Estación Central">
                              Estación Central
                            </option>
                            <option value="Huechuraba">Huechuraba</option>
                            <option value="Independencia">Independencia</option>
                            <option value="La Cisterna">La Cisterna</option>
                            <option value="La Florida">La Florida</option>
                            <option value="La Granja">La Granja</option>
                            <option value="La Pintana">La Pintana</option>
                            <option value="La Reina">La Reina</option>
                            <option value="Lampa">Lampa</option>
                            <option value="Las Condes">Las Condes</option>
                            <option value="Lo Barnechea">Lo Barnechea</option>
                            <option value="Lo Espejo">Lo Espejo</option>
                            <option value="Lo Prado">Lo Prado</option>
                            <option value="Macul">Macul</option>
                            <option value="Maipú">Maipú</option>
                            <option value="Nuñoa">Nuñoa</option>
                            <option value="Talagante">Talagante</option>
                            <option value="Tíl Tíl">Tíl Tíl</option>
                          </Form.Control>
                        ) : this.state.fields['region'] == 14 ? (
                          <Form.Control
                            as="select"
                            className="form-control seleccion comunas nuwField8_required"
                            id="COMUNA"
                            name="commune"
                            onChange={this.handleChange.bind(this, 'commune')}
                            value={this.state.fields['commune'] || ''}
                            custom>
                            <option value="">Selecciona una comuna</option>
                            <option value="Corral">Corral</option>
                            <option value="Futrono">Futrono</option>
                            <option value="La Unión">La Unión</option>
                            <option value="Lago Ranco">Lago Ranco</option>
                            <option value="Lanco">Lanco</option>
                            <option value="Los Lagos">Los Lagos</option>
                            <option value="Máfil">Máfil</option>
                            <option value="Mariquina">Mariquina</option>
                            <option value="Paillaco">Paillaco</option>
                            <option value="Panguipulli">Panguipulli</option>
                            <option value="Río Bueno">Río Bueno</option>
                            <option value="Valdivia">Valdivia</option>
                          </Form.Control>
                        ) : this.state.fields['region'] == 15 ? (
                          <Form.Control
                            as="select"
                            className="form-control seleccion comunas nuwField8_required"
                            id="COMUNA"
                            name="commune"
                            onChange={this.handleChange.bind(this, 'commune')}
                            value={this.state.fields['commune'] || ''}
                            custom>
                            <option value="">Selecciona una comuna</option>
                            <option value="Arica">Arica</option>
                            <option value="Camarones">Camarones</option>
                            <option value="General Lagos">General Lagos</option>
                            <option value="Putre">Putre</option>
                          </Form.Control>
                        ) : (
                          <Form.Control
                            as="select"
                            className="form-control seleccion comunas nuwField8_required"
                            id="COMUNA"
                            name="commune"
                            onChange={this.handleChange.bind(this, 'commune')}
                            value={this.state.fields['commune'] || ''}
                            custom>
                            <option value="">Selecciona una comuna</option>
                          </Form.Control>
                        )}
                      </div>
                      {this.state.errors['commune'] ? (
                        <span style={{ color: '#a94442' }}>
                          {this.state.errors['commune']}
                        </span>
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div style={{ clear: 'both' }}></div>
              <div className="row">
                <div className="col-6">
                  <div className="form-general">
                    <div className="form-group has-feedback">
                      <label className="control-label">Dirección</label>
                      <input
                        id="21-33-372-72-DIRECCION"
                        name="address"
                        placeholder="Mi calle 321, Depto 53"
                        className="nuwField9 nuwField9_required DIRECCIONASEGURADO"
                        type="text"
                        onChange={this.handleChange.bind(this, 'address')}
                        value={this.state.fields['address'] || ''}
                        size="500"
                        maxLength="500"
                      />
                      {this.state.errors['address'] ? (
                        <span style={{ color: '#a94442' }}>
                          {this.state.errors['address']}
                        </span>
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div style={{ clear: 'both' }}></div>
              <div className="grid">
                <div className="col-6 verificacion">
                  <div className="row">
                    <br />
                    <div id="condiciones">
                      {this.state.acepta ? (
                        <input
                          id="aceptaCondiciones"
                          name="aceptaCondiciones"
                          type="checkbox"
                          className="form-control checkaceptacion"
                          checked={this.state.check}
                          onClick={this.aceptaCondiciones}
                          onChange={this.handleCheck}
                        />
                      ) : (
                        <a
                          onClick={this.aceptaCondiciones}
                          target="_blank"
                          href={Pdf2}>
                          <input
                            id="aceptaCondiciones"
                            name="aceptaCondiciones"
                            type="checkbox"
                            className="form-control checkaceptacion"
                            checked={this.state.check}
                            onClick={this.aceptaCondiciones}
                            onChange={this.handleCheck}
                          />
                        </a>
                      )}
                      <label className="cont rol-label">
                        <a
                          onClick={this.aceptaCondiciones}
                          target="_blank"
                          href={Pdf}>
                          Primero debes leer y luego aceptar las{' '}
                          <strong>
                            <u style={{ textDecoration: 'none' }}>condiciones del seguro</u>
                          </strong>
                        </a>
                        {this.state.errors['condSeguro'] ? (
                          <p>
                            <span style={{ color: '#a94442' }}>
                              {this.state.errors['condSeguro']}
                            </span>
                          </p>
                        ) : (
                          ''
                        )}
                      </label>

                      <br />
                    </div>

                    <div id="Div1">
                      <label className="cont rol-label">
                        Nota: Al continuar se enviará un SMS a tu celular con un
                        Código de Verificación
                      </label>
                    </div>
                    <div className="form-general">
                      {this.state.error && (
                        <p className="center " style={{ color: '#fa9600' }}>
                          {this.state.errorMessage}
                        </p>
                      )}
                      <div className="form-group has-feedback">
                        <div className="btn-acciones">
                          <button
                            id="enviar"
                            name="comprar"
                            className="accion ejecucion btn-centrado"
                            type="submit"
                            disabled={this.state.disabledbutton}>
                            <span>
                              {this.state.disabledbutton
                                ? 'Enviando...'
                                : 'Continuar'}
                            </span>
                          </button>
                        </div>
                      </div>
                    </div>
                    <div id="telefonos">
                      <img src={telefono} />
                      <a href="tel:6002007000">600 200 7000</a>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </Container>
    );
  }
}

export default withRouter(InsuredForm);
