import React from 'react';

// import '../../assets/css/Home/ResumenSeguro.css';

const datosseg = props => {

  return (
    <div className="grid">
      <div className="col-12">
        <div className="llamado">
          <h2>Datos del asegurado</h2>
          <span>Todos los datos son obligatorios</span>
        </div>
      </div>
    </div>
  );
};

export default datosseg;
